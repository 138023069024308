<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Inserir um novo Sistema</h3>
        <p style="text-align: start;">Insira um novo sistema para gerir os processos de implantação.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.push('/lista/sistema')">voltar</button>
    </div>
    <form class="formulario" autocomplete='off'>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            label="Nome do Sistema"
            :error-messages="erros.nome"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="descricao"
            @mousedown="(e) => selecionarcampo(e)"
            name="descricao"
            :error-messages="erros.descricao"
            label="Descrição do sistema"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="salvarSistema">
            criar sistema
          </v-btn>
        </v-col>
      </v-row>    
    </form>
  </v-container>

</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import SistemaServico from '@/services/sistema.service.js'
export default {
  data(){
    return {
      nome: null,
      descricao: null,
      reqloading: false,
      erros: {
        nome: '',
        descricao: '',
      }
    }
  },
  methods: {

    async salvarSistema(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        let sistema = {
          nome: this.nome,
          descricao : this.descricao
        }
        await SistemaServico.criarSistema(sistema).then(res =>{
          if(res.status === 200){
            alertar('success', '=)', 'Sistema criado com sucesso!')
            this.reqloading = false
            this.nome = null
            this.descricao = null
          }
        }).catch(e =>{
          this.reqloading = false
          console.log(e)
        })
      }
    },
    validacao(){
      let validado = true
      if(!this.nome || this.nome.trim() === ''){
        validado = false
        this.erros.nome = 'O campo nome é obrigatório.'
      }
      if(!this.descricao || this.descricao.trim() === ''){
        validado = false
        this.erros.descricao = 'O campo descrição é obrigatório.'
      }
      return validado
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    }


  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>