<template>
  <div>
    <CriarSistema />
  </div>
</template>

<script>
import CriarSistema from '@/components/Sistema/CriarSistema'
export default {
  components: {CriarSistema},
  data(){
    return{}
  }
}
</script>

<style>

</style>